// bootstrap variables override
$popover-max-width: none;

@import 'bootstrap/scss/bootstrap.scss';

@for $i from 2 through 8 {
  ui-panel:nth-of-type(#{$i}) > .toggle {
    left: 6.5rem * ($i - 1) + 0.5rem;
  }
}

bs-dropdown-container {
  z-index: $zindex-dropdown;
}

.popover {
  min-width: 18rem;
  font-size: 0.8rem;
}
.ui-panels .toggle {
  border-radius: 0.2rem;
  &,
  .toolbar-container .btn {
    background: white;
  }
}

.modal,
.form-control {
  font-size: 0.9rem;
}

// hardcoded symbol font
// TODO: load symbol fonts from API
@font-face {
  font-family: 'Font Awesome 5 Free Solid';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url('./assets/FontAwesome.ttf');
}
@font-face {
  font-family: 'Mapkey Icons';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url('./assets/MapkeyIcons.ttf');
}

.tooltip-inner {
  max-width: 250px;
  max-height: 135px;
}
